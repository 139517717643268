@import "DOMAIN/app.scss";

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    border-top: 0px;
    border-bottom: 4px dashed;
    content: "";
}
.navbar-inverse {
    background-color: rgb(87, 87, 86);
    border-color: rgb(77, 77, 76);
}
.navbar-static-top {
    z-index: 1000;
    border-width: 0px 0px 1px;
}
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.navbar-header::after,
.navbar-header::before {
    content: " ";
    display: table;
}
.navbar-toggle {
    position: relative;
    float: right;
    margin-right: 15px;
    padding: 9px 10px;
    margin-top: 15.5px;
    margin-bottom: 15.5px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.panel-heading .accordion-toggle.collapsed::after {
    content: "";
}
.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}
.navbar-brand {
    float: left;
    padding: 22.5px 15px;
    font-size: 18px;
    line-height: 20px;
    height: 65px;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    border-top: 0px;
    border-bottom: 4px dashed;
    content: "";
}
.navbar-nav {
    margin: 11.25px -15px;
}
.webui-popover .dropdown-menu {
    display: block;
    position: relative;
    top: 0px;
    border: none;
    box-shadow: none;
    float: none;
}
.btn-default.active,
.btn-default:active,
.btn-default:hover,
.open > .btn-default.dropdown-toggle {
    color: rgb(85, 85, 85);
    background-color: rgb(213, 213, 213);
    border-color: rgb(195, 195, 195);
}
.caret {
    display: inline-block;
    width: 0px;
    height: 0px;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}
.webui-popover .dropdown-menu {
    display: block;
    position: relative;
    top: 0px;
    border: none;
    box-shadow: none;
    float: none;
}
.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}
.glyphicon-th-list::before {
    content: "";
}
.glyphicon-plus::before {
    content: "+";
}
.glyphicon-search::before {
    content: "";
}
.webui-popover.fade,
.webui-popover.fade-out {
    transition: opacity 0.15s linear 0s;
    opacity: 0;
}
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active {
    background-color: rgb(245, 245, 245);
}
.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.row {
    margin-left: -15px;
    margin-right: -15px;
}
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}
.panel {
    margin-bottom: 20px;
    background-color: rgb(255, 255, 255);
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px;
}
.panel-default {
    border-color: transparent;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.webui-no-padding .list-group-item {
    border-right: none;
    border-left: none;
}
.webui-no-padding .list-group-item {
    border-right: none;
    border-left: none;
}
.btn-default .badge {
    color: rgb(238, 238, 238);
    background-color: rgb(85, 85, 85);
}
.badge.level-alert,
.badge.level-all,
.badge.level-critical,
.badge.level-debug,
.badge.level-emergency,
.badge.level-error,
.badge.level-info,
.badge.level-notice,
.badge.level-warning,
.level,
.level a,
.level i {
    color: rgb(255, 255, 255);
}
.level {
    padding: 2px 6px;
    text-shadow: rgba(0, 0, 0, 0.3) 0px 1px 1px;
    border-radius: 2px;
    font-size: 0.9em;
    font-weight: 600;
}
.datepicker td.disabled {
    color: rgb(204, 204, 204) !important;
}
.badge.level-alert,
.badge.level-all,
.badge.level-critical,
.badge.level-debug,
.badge.level-emergency,
.badge.level-error,
.badge.level-info,
.badge.level-notice,
.badge.level-warning,
.level,
.level a,
.level i {
    color: rgb(255, 255, 255);
}
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > thead > tr > td.info,
.table > thead > tr > th.info {
    background-color: rgb(52, 152, 219);
}
.badge.level-alert,
.badge.level-all,
.badge.level-critical,
.badge.level-debug,
.badge.level-emergency,
.badge.level-error,
.badge.level-info,
.badge.level-notice,
.badge.level-warning,
.level,
.level a,
.level i {
    color: rgb(255, 255, 255);
}
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}
.form-group {
    margin-bottom: 15px;
}
.form-control-static.input-lg,
.form-control-static.input-sm,
.input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn,
.input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn {
    padding-left: 0px;
    padding-right: 0px;
}
.form-control,
output {
    display: block;
    font-size: 14px;
    line-height: 1.42857;
    color: rgb(85, 85, 85);
}
.form-control-static.input-lg,
.form-control-static.input-sm,
.input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn,
.input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn {
    padding-left: 0px;
    padding-right: 0px;
}
.form-control-static.input-lg,
.form-control-static.input-sm,
.input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn,
.input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn {
    padding-left: 0px;
    padding-right: 0px;
}
.btn-primary {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 179, 167);
    border-color: rgb(0, 154, 143);
}
.text-center {
    text-align: center;
}
.no-p-t {
    padding-top: 0px !important;
}
.no-p-b {
    padding-bottom: 0px !important;
}
.pagination {
    display: inline-block;
    padding-left: 0px;
    margin: 20px 0px;
    border-radius: 4px;
}
.table-responsive {
    overflow-x: auto;
    min-height: 0.01%;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}
.table-bordered,
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
    border: 1px solid rgb(238, 238, 238);
}
.text-right {
    text-align: right;
}
.label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: rgb(255, 255, 255);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}
.label-default {
    background-color: rgb(102, 102, 102);
}
.label-primary {
    background-color: #00b3a7;
}
.btn-group-xs > .btn,
.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 2px;
}
.btn-default {
    color: rgb(85, 85, 85);
    background-color: rgb(238, 238, 238);
    border-color: rgb(226, 226, 226);
}
#logs-table > tbody > tr > td.stack,
#logs-table > tfoot > tr > td.stack,
#logs-table > thead > tr > td.stack {
    padding: 0px;
    background-color: rgb(246, 246, 246);
    border-top: 1px solid rgb(209, 209, 209);
    max-width: 0px;
    overflow-x: auto;
}
#logs-table .stack-content {
    padding: 8px;
    color: rgb(255, 255, 255);
    font-family: consolas, Menlo, Courier, monospace;
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
    background: rgb(72, 72, 72);
}
.panel-footer {
    padding: 10px 15px;
    background-color: rgb(245, 245, 245);
    border-top: 1px solid transparent;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.modal,
.modal-open {
    overflow: hidden;
}
.webui-popover.fade,
.webui-popover.fade-out {
    transition: opacity 0.15s linear 0s;
    opacity: 0;
}
.modal.fade .modal-dialog {
    transform: translateY(-25%);
    transition: transform 0.3s ease-out 0s, -webkit-transform 0.3s ease-out 0s;
}
.modal-content {
    position: relative;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 9px;
    background-clip: padding-box;
    outline: 0px;
}
.modal-header {
    padding: 15px;
    border-bottom: 1px solid rgb(229, 229, 229);
}
.webui-popover-inner .close {
    font-family: arial;
    margin: 8px 10px 0px 0px;
    float: right;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    color: rgb(0, 0, 0);
    text-shadow: rgb(255, 255, 255) 0px 1px 0px;
    opacity: 0.2;
    text-decoration: none;
}
.modal-title {
    margin: 0px;
    line-height: 1.42857;
}
.modal-body {
    position: relative;
    padding: 20px;
}
.has-success .checkbox,
.has-success .checkbox-inline,
.has-success.checkbox-inline label,
.has-success.checkbox label,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.radio-inline label,
.has-success.radio label {
    color: rgb(255, 255, 255);
}
.is-required {
    color: rgb(169, 68, 66);
    padding-left: 3px;
}
.has-success .checkbox,
.has-success .checkbox-inline,
.has-success.checkbox-inline label,
.has-success.checkbox label,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.radio-inline label,
.has-success.radio label {
    color: rgb(255, 255, 255);
}
.checkbox,
.radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}
.no-m-t {
    margin-top: 0px !important;
}
.modal-footer {
    padding: 20px;
    text-align: right;
    border-top: 1px solid rgb(229, 229, 229);
}
.m-r-sm {
    margin-right: 5px !important;
}

/** logs */
#logs-table > tbody > tr > td.stack,
#logs-table > tfoot > tr > td.stack,
#logs-table > thead > tr > td.stack {
    padding: 0;
    border-top: none;
    background-color: #f6f6f6;
    border-top: 1px solid #d1d1d1;
    max-width: 0;
    overflow-x: auto;
}

#logs-table .stack-content {
    padding: 8px;
    color: #fff;
    font-family: consolas, Menlo, Courier, monospace;
    font-size: 12px;
    font-weight: 400;
    white-space: pre-line;
    background: #484848;
}

.level {
    padding: 2px 6px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    font-size: 0.9em;
    font-weight: 600;
}

.level a {
    text-decoration: none !important;
    color: #fff;
}

.badge.level-all,
.badge.level-emergency,
.badge.level-alert,
.badge.level-critical,
.badge.level-error,
.badge.level-warning,
.badge.level-notice,
.badge.level-info,
.badge.level-debug,
.level,
.level i {
    color: #fff;
}

.badge.level-all,
.level.level-all,
.info-box.level-all {
    background-color: #8a8a8a;
}

.badge.level-emergency,
.level.level-emergency,
.info-box.level-emergency {
    background-color: #b71c1c;
}

.badge.level-alert,
.level.level-alert,
.info-box.level-alert {
    background-color: #d32f2f;
}

.badge.level-critical,
.level.level-critical,
.info-box.level-critical {
    background-color: #f44336;
}

.badge.level-error,
.level.level-error,
.info-box.level-error {
    background-color: #ff5722;
}

.badge.level-warning,
.level.level-warning,
.info-box.level-warning {
    background-color: #ff9100;
}

.badge.level-notice,
.level.level-notice,
.info-box.level-notice {
    background-color: #4caf50;
}

.badge.level-info,
.level.level-info,
.info-box.level-info {
    background-color: #1976d2;
}

.badge.level-debug,
.level.level-debug,
.info-box.level-debug {
    background-color: #90caf9;
}

.badge.level-empty,
.level.level-empty {
    background-color: #d1d1d1;
}
.pagination > .active > span {
    z-index: 3;
    color: #fff;
    background-color: #00b3a7;
    border-color: #009a8f;
    cursor: default;
}
